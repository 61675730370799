



















import { mapState } from 'vuex';
import Base from './Base.vue';
import MfToggleWrap from './ToggleWrap.vue';
import MfRelatedKeywords from './RelatedKeywords.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'mf-related-keywords-toggle',
  components: { MfToggleWrap, MfRelatedKeywords },
  extends: Base,
  props: {
    bodyClassToggle: {
      type: String,
    },
    initOn: {
      type: Boolean,
    },
    ajaxUrl: {
      type: String,
    },
    headText: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      mfHelper: 'mfHelper',
    }),
    rootClass(): Record<string, boolean> {
      const map = {
        mf_helper: this.mfHelper === 'RelatedKeywordsToggle',
      };
      return map;
    },
  },
});
