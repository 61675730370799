





























import { mapState } from 'vuex';
import Base from './Base.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'mf-keyword-ranking',
  extends: Base,
  props: {
    ajaxUrl: {
      type: String,
      default: '',
    },
    headText: {
      type: String,
    },
  },
  computed: {
    stateData(): any {
      const state = this.$store.state;
      return (this.ajaxUrl ? state.dataMap[this.ajaxUrl] : state) || {};
    },
    keywordRanking(): any {
      return this.stateData.keywordRanking;
    },
    ...mapState({
      mfHelper: 'mfHelper',
    }),
    rootClass(): Record<string, any> {
      const map = {
        mf_helper: this.mfHelper === 'KeywordRanking',
      };
      return map;
    },
    items(): any {
      return this.keywordRanking && this.keywordRanking.result;
    },
  },
  methods: {
    extendRouteQuery(params: Record<string, any>) {
      const resetParam = {
        ct: '',
        d: '',
        page: 1,
        doctype: 'all',
      };
      return (this.$root as any).extendRouteQuery({
        query: { ...resetParam, ...params },
        searchUrl: this.ajaxUrl,
      });
    },
  },
  mounted() {
    if (this.ajaxUrl) {
      this.$store.dispatch('updateSearchUrl', this.ajaxUrl);
    }
    this.$store.dispatch('enableKeywordRanking', { searchUrl: this.ajaxUrl });
  },
});
