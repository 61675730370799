





















































import { mapState } from 'vuex';
import Base from './Base.vue';
import Vue from 'vue';
import { OrgQuery, SearchResult, StoreState } from '@/store/store.entities';
// import router from '@/router';

export interface PagerItem {
  page: number;
  params: any;
}

export default Vue.extend({
  name: 'searchResultsPager',
  extends: Base,
  props: {
    ajaxUrl: {
      type: String,
      default: '',
    },
    pagerItemCount: {
      type: Number,
      validator: function (v) {
        return v > 0;
      },
      default: 5,
    },
    pagerPagenumBlank: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stateData(): StoreState {
      const state = this.$store.state;
      return (this.ajaxUrl ? state.dataMap[this.ajaxUrl] : state) || {};
    },
    searchResult(): SearchResult {
      return this.stateData.searchResult;
    },
    ...mapState({
      mfHelper: 'mfHelper',
    }),
    rootClass(): Record<string, boolean> {
      return {
        mf_helper: this.mfHelper === 'SearchResultsPager',
      };
    },
    organic(): SearchResult['organic'] {
      return this.searchResult?.organic;
    },
    params(): OrgQuery {
      return this.searchResult?.params;
    },
    pagenum(): number {
      try {
        return parseInt(this.params.page);
      } catch (e) {
        console.error(e);
        return NaN;
      }
    },
    hits(): number {
      try {
        return this.organic?.hits;
      } catch (e) {
        console.error(e);
        return NaN;
      }
    },
    pagerItems(): PagerItem[][] {
      const lastPage = Math.ceil(
        Math.min(1000, this.hits) / Number(this.params.pagemax),
      );
      const pagerItemCount = parseInt(String(this.pagerItemCount));
      let start, end;

      if (lastPage < pagerItemCount) {
        // 全体が足りないので、先頭から末尾まで
        start = 1;
        end = lastPage;
      } else if (this.pagenum < pagerItemCount / 2 + 1) {
        // 前が足りないので、先頭から
        start = 1;
        end = pagerItemCount;
      } else if (lastPage - this.pagenum < pagerItemCount / 2) {
        // 後ろが足りないので、末尾までmaxsize
        start = lastPage - pagerItemCount + 1;
        end = lastPage;
      } else {
        // 前後にmaxsize
        start = Math.floor(this.pagenum - pagerItemCount / 2 + 1);
        end = start + pagerItemCount - 1;
      }
      // console.log('start(' + (typeof start) + '):' + start)
      // console.log('end(' + (typeof end) + '):' + end)

      const ret = [[], []] as PagerItem[][];

      for (let page = start; page < this.pagenum; page++) {
        ret[0].push({ page, params: this.extendRouteQuery({ page }) });
      }
      for (let page = this.pagenum + 1; page <= end; page++) {
        ret[1].push({ page, params: this.extendRouteQuery({ page }) });
      }
      return ret;
    },
    pagerBeforeItems(): PagerItem[] {
      return this.pagerItems[0];
    },
    pagerAfterItems(): PagerItem[] {
      return this.pagerItems[1];
    },
    pagerFirstParams(): any {
      const page = 1;
      return this.extendRouteQuery({ page });
    },
    pagerPrevParams(): any {
      const page = this.pagenum - 1;
      if (!(page >= 1)) return null;

      return this.extendRouteQuery({ page });
    },
    pagerNextParams(): any {
      if (!this.pagerAfterItems) return null;
      if (this.pagerAfterItems.length === 0) return null;
      return this.extendRouteQuery({ page: this.pagenum + 1 });
    },
  },
  methods: {
    extendRouteQuery(params: Record<string, any>): any {
      return (this.$root as any).extendRouteQuery({
        query: params,
        searchUrl: this.ajaxUrl,
      });
    },
  },
  mounted() {
    if (this.ajaxUrl) {
      this.$store.dispatch('updateSearchUrl', this.ajaxUrl);
    }
  },
});
