















import { mapState } from 'vuex';
import Base from './Base.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'mf-toggle-wrap',
  extends: Base,
  props: {
    bodyClassToggle: {
      type: String,
    },
    initOn: {
      type: Boolean,
    },
    noReset: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      toggleOn: false,
    };
  },
  computed: {
    ...mapState({
      mfHelper: 'mfHelper',
    }),
    rootClass(): Record<string, boolean> {
      return {
        mf_toggle_on: this.toggleOn,
        mf_helper: this.mfHelper === 'ToggleWrap',
      };
    },
  },
  methods: {
    clickToggle(): void {
      this.toggle();
    },
    toggle(v?: unknown): void {
      this.toggleOn = typeof v === 'boolean' ? v : !this.toggleOn;
      if (this.bodyClassToggle) {
        try {
          document.body.classList.toggle(this.bodyClassToggle, this.toggleOn);
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
  watch: {
    $route(): void {
      if (!this.noReset) {
        this.toggle(this.initOn);
      }
    },
  },
  mounted() {
    if (this.initOn) {
      this.toggle(true);
    }
  },
});
