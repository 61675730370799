






























import { mapState } from 'vuex';
import Base from './Base.vue';
import Vue from 'vue';
import { OrgQuery, SearchResult, StoreState } from '@/store/store.entities';

export default Vue.extend({
  name: 'searchResultsHeader',
  extends: Base,
  props: {
    ajaxUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    stateData(): StoreState {
      const state = this.$store.state;
      return (this.ajaxUrl ? state.dataMap[this.ajaxUrl] : state) || {};
    },
    searchResult(): SearchResult {
      return this.stateData.searchResult;
    },
    ...mapState({
      mfHelper: 'mfHelper',
    }),
    rootClass(): Record<string, boolean> {
      return {
        mf_finder_organic_zerohit: this.hits === 0,
        mf_finder_no_query: !this.params.q,
        mf_helper: this.mfHelper === 'SearchResultsHeader',
      };
    },
    organic(): SearchResult['organic'] {
      return this.searchResult?.organic;
    },
    params(): OrgQuery {
      return this.searchResult?.params;
    },
    range(): number[] {
      try {
        return this.organic.range;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    hits(): number {
      return this.organic?.hits;
    },
    isExistResults(): boolean {
      return this.organic?.hits > 0;
    },
  },
  methods: {
    extendRouteQuery(params: any) {
      return (this.$root as any).extendRouteQuery({
        query: params,
        searchUrl: this.ajaxUrl,
      });
    },
  },
  mounted() {
    if (this.ajaxUrl) {
      this.$store.dispatch('updateSearchUrl', this.ajaxUrl);
    }
  },
});
