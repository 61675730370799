



















import { mapState } from 'vuex';
import Base from './Base.vue';
import MfToggleWrap from './ToggleWrap.vue';
import MfKeywordRanking from './KeywordRanking.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'mf-keyword-ranking-toggle',
  components: { MfToggleWrap, MfKeywordRanking },
  extends: Base,
  props: {
    bodyClassToggle: {
      type: String,
    },
    initOn: {
      type: Boolean,
    },
    ajaxUrl: {
      type: String,
    },
    headText: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      mfHelper: 'mfHelper',
    }),
    rootClass() {
      const map = {
        mf_helper: this.mfHelper === 'KeywordRankingToggle',
      };
      return map;
    },
  },
});
