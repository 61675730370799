
































import Vue from 'vue';

export default Vue.extend({
  name: 'mf-drilldown-item',
  props: {
    ajaxUrl: {
      type: String,
      default: '',
    },
    model: {
      type: Object,
    },
  },
  data: function () {
    return {
      open: false,
    };
  },
  computed: {
    title() {
      return this.model.title;
    },
    path() {
      return this.model.path;
    },
    depth() {
      return this.model.depth;
    },
    count() {
      return this.model.count;
    },
    child() {
      return this.model.child;
    },
    selected() {
      return this.model.selected;
    },
    rootClass(): Record<string, boolean> {
      return {
        ['mf_finder_drilldown_item_depth_' + this.depth]: true,
        mf_finder_drilldown_item_leaf: this.child.length === 0,
        mf_finder_drilldown_item_open: this.child.length > 0 && this.open,
        mf_finder_drilldown_item_selected: this.selected,
        mf_finder_drilldown_item_nohit: !parseInt(this.count),
      };
    },
  },
  methods: {
    extendRouteQuery(params: Record<string, any>) {
      const resetParam = {
        page: 1,
      };
      return (this.$root as any).extendRouteQuery({
        query: { ...resetParam, ...params },
        searchUrl: this.ajaxUrl,
      });
    },
    toggle() {
      if (this.child) {
        this.open = !this.open;
      }
    },
  },
  mounted() {
    if (this.ajaxUrl) {
      this.$store.dispatch('updateSearchUrl', this.ajaxUrl);
    }
    this.open = this.model.opened;
  },
});
