



























import { mapState } from 'vuex';
import Base from './Base.vue';
import MfToggleWrap from './ToggleWrap.vue';
import MfDrilldown from './Drilldown.vue';
import MfDrilldownReset from './DrilldownReset.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'mf-drilldown-toggle',
  components: { MfToggleWrap, MfDrilldown, MfDrilldownReset },
  extends: Base,
  props: {
    ajaxUrl: {
      type: String,
      default: '',
    },
    bodyClassToggle: {
      type: String,
    },
    initOn: {
      type: Boolean,
    },
    headText: {
      type: String,
    },
    resetText: {
      type: String,
      default: 'reset',
    },
    resetBadgeText: {
      type: String,
      default: 'reset',
    },
    bodyClassSelected: {
      type: String,
      default: 'mf_finder_drilldown_selected',
    },
  },
  computed: {
    ...mapState({
      mfHelper: 'mfHelper',
    }),
    rootClass() {
      const map = {
        mf_helper: this.mfHelper === 'DrilldownToggle',
      };
      return map;
    },
    isDrilldownItemSelected() {
      return this.$store.state.isDrilldownItemSelected;
    },
  },
});
