// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import router from './router';
import store from './store';

import { isEqual, omitBy } from 'lodash-es';

import FormSerialize from 'form-serialize';
import qs from 'query-string';

// Polyfills
import '@/polyfills/ie11Polyfills';

import { G } from './common/utils';

import MfSearchBox from './components/SearchBox.vue';
import MfSearchResults from './components/SearchResults.vue';
import MfSearchResultsHeader from './components/SearchResultsHeader.vue';
import MfSearchResultsPager from './components/SearchResultsPager.vue';
import MfFeaturedContents from './components/FeaturedContents.vue';
import MfToggleWrap from './components/ToggleWrap.vue';
import MfDrilldown from './components/Drilldown.vue';
import MfKeywordRanking from './components/KeywordRanking.vue';
import MfRelatedKeywords from './components/RelatedKeywords.vue';
import MfDrilldownToggle from './components/DrilldownToggle.vue';
import MfDrilldownReset from './components/DrilldownReset.vue';
import MfKeywordRankingToggle from './components/KeywordRankingToggle.vue';
import MfRelatedKeywordsToggle from './components/RelatedKeywordsToggle.vue';

type Class2Elements = Record<string, Element[] | HTMLCollectionOf<Element>>;

// Vueの適用先エレメント(headerとcontainerの両方)
let cls2elms: Class2Elements = {};

const containerCls = 'mf_finder_container';
const headerCls = 'mf_finder_header';
const searchResultsCustomEle = document.querySelector('mf-search-results');

// headerがcontainerのツリー内なら、衝突防止のためheaderは無視
cls2elms[headerCls] = Array.prototype.filter.call(
  document.getElementsByClassName(headerCls),
  (el) => !el.closest(`.${containerCls}`),
);
if (cls2elms[headerCls].length === 0) {
  delete cls2elms[headerCls];
}

cls2elms[containerCls] = document.getElementsByClassName(containerCls);

/**
 * 初期化ルーチン
 * @param  {Object} cls2elms {<class>: [elm,...]}
 */
function main(cls2elms: Class2Elements) {
  Vue.config.productionTip = false;

  router.afterEach((to, from) => {
    // console.log('router.afterEach');
    // console.log('to:' + JSON.stringify(to))
    // console.log('from:' + JSON.stringify(from))

    // historyモードの時、ページのURLにクエリ文字列が存在すると検索が実行される不具合の対応
    // 検索結果用のカスエレが存在しない or クエリ文字列に "q" が存在しなければ処理を抜ける
    // params_nameを設定している際に、クエリ文字列に "q" 相当のものが存在しない場合は処理を抜ける
    if (
      searchResultsCustomEle === null ||
      !(store.state.paramNames.q in to.query)
    )
      return;

    // フラグメントを守る機能を使わない場合は、そのまま適用
    if (G.mfx.disableFragmentGuard) {
      Vue.nextTick(() => {
        store.dispatch('fetchSearchDataByQuery', to.query);
      });
      return;
    }

    // fromのqueryをデフォルトとしてtoのqueryでオーバーライドする
    const query = { ...from.query, ...to.query };

    // to.queryとqueryの内容が同じなら適用し、不一致ならreplace
    if (isEqual(query, to.query)) {
      // 必要なパラメータ(何が必要かは下記の配列を参照)
      const paramsQuery = Object.keys(query);
      const paramsQueryTarget = [
        store.state.paramNames.page,
        store.state.paramNames.imgsize,
        store.state.paramNames.doctype,
        store.state.paramNames.sort,
        store.state.paramNames.pagemax,
      ];

      // パラメータのバリデーションバリュー
      const numberRegex = /^[1-9]\d*$/;
      const imgSizeNumberRegex = ['0', '1', '2', '3'];
      const doctypeRegex = ['all', 'html', 'pdf', 'doc', 'xls', 'ppt'];
      const sortRegex = ['0', '1'];
      const pageMaxRegex = ['10', '20', '30', '50', '100'];

      // 必要なパラメータが全てあるかを判断する関数
      const isValid = paramsQueryTarget.every((item) =>
        paramsQuery.includes(item),
      );

      // パラメータ名を取得
      const pageParams = store.state.paramNames.page;
      const imgsizeParams = store.state.paramNames.imgsize;
      const doctypeParams = store.state.paramNames.doctype;
      const sortParams = store.state.paramNames.sort;
      const pagemaxParams = store.state.paramNames.pagemax;

      /**********************************************
       * 必要なパラメータがない場合、デフォルト値を設定する
       ***********************************************/
      if (!isValid) {
        console.warn('not included necessary parameters');

        // default値を設定
        const query = {
          ...to.query,
          [pageParams]: '1',
          [imgsizeParams]: '1',
          [doctypeParams]: 'all',
          [sortParams]: '0',
          [pagemaxParams]: '10',
        };

        // routing
        router.push({ query } as any);
      } else {
        query[pageParams];
        query[imgsizeParams];
        query[doctypeParams];
        query[sortParams];
        query[pagemaxParams];
      }

      /***********************************************
       * pageパラメータのバリデーション(数字以外と0は1に変換)
       ***********************************************/
      if (
        !numberRegex.test(query[pageParams] as string) &&
        Object.keys(query).join('').includes(`${pageParams}`) // queryの中のpageがあるかどうか
      ) {
        console.warn('include string or 0');
        query[pageParams] = '1';

        router.push({ query } as any);
      }

      /**************************************************
       * imgsizeパラメータのバリデーション(数字以外と0は1に変換)
       **************************************************/
      if (
        !imgSizeNumberRegex.includes(query[imgsizeParams] as string) &&
        Object.keys(query).join('').includes(`${imgsizeParams}`) // queryの中のimgsizeがあるかどうか
      ) {
        console.warn('include string or except 0,1,2,3');
        query[imgsizeParams] = '1';

        router.push({ query } as any);
      }

      /******************************************************************************************
       * doctypeパラメータのバリデーション('all', 'html', 'pdf', 'doc', 'xls', 'ppt'はallに変換)
       ******************************************************************************************/
      if (
        !doctypeRegex.includes(query[doctypeParams] as string) &&
        Object.keys(query).join('').includes(`${doctypeParams}`) // queryの中のdoctypeがあるかどうか
      ) {
        console.warn('include number or except all,html,pdf,doc,xls,ppt');
        query[doctypeParams] = 'all';

        router.push({ query } as any);
      }

      /********************************************
       * sortパラメータのバリデーション(0,1以外は0に変換)
       ********************************************/
      if (
        !sortRegex.includes(query[sortParams] as string) &&
        Object.keys(query).join('').includes(`${sortParams}`) // queryの中のsortがあるかどうか
      ) {
        console.warn('include string or except 0,1');
        query[sortParams] = '0';

        router.push({ query } as any);
      }

      /************************************************************
       * pagemaxパラメータのバリデーション(10,20,30,50,100以外は10に変換)
       ************************************************************/
      if (
        !pageMaxRegex.includes(query[pagemaxParams] as string) &&
        Object.keys(query).join('').includes(`${pagemaxParams}`) // queryの中のpagemaxがあるかどうか
      ) {
        console.warn('include string or except 10,20,30,50,100');
        query[pagemaxParams] = '10';

        router.push({ query } as any);
      }

      Vue.nextTick(() => {
        store.dispatch('fetchSearchDataByQuery', query);
      });
    } else {
      Vue.nextTick(() => {
        router.replace({ ...to, ...{ query } } as any);
      });
    }
  });

  for (const [cls, elms] of Object.entries(cls2elms)) {
    if (G.mfx.loaded[cls]) return;
    if (elms.length === 0) return;

    // Define the component names
    const componentNames = [
      'mf-search-box',
      'mf-search-results',
      'mf-search-results-header',
      'mf-search-results-pager',
      'mf-featured-contents',
      'mf-toggle-wrap',
      'mf-drilldown',
      'mf-keyword-ranking',
      'mf-related-keywords',
      'mf-drilldown-toggle',
      'mf-drilldown-reset',
      'mf-keyword-ranking-toggle',
      'mf-related-keywords-toggle',
    ];
    // Find all instances of our custom elements and get their parent elements
    const parentElms = componentNames.flatMap(
      (name) =>
        Array.from(document.getElementsByTagName(name))
          .map((el) => el.parentElement)
          .filter(Boolean), // Remove null/undefined entries
    );
    // Remove duplicates (in case multiple components share the same parent)
    const uniqueParentElms = Array.from(new Set(parentElms));
    G.mfx.loaded[cls] = uniqueParentElms.map((elm) => {
      /* eslint-disable no-new */
      return new Vue({
        el: elm,
        data: Object.freeze({ window }),
        store,
        router,
        components: {
          MfSearchBox,
          MfSearchResults,
          MfSearchResultsHeader,
          MfSearchResultsPager,
          MfFeaturedContents,
          MfToggleWrap,
          MfDrilldown,
          MfKeywordRanking,
          MfRelatedKeywords,
          MfDrilldownToggle,
          MfDrilldownReset,
          MfKeywordRankingToggle,
          MfRelatedKeywordsToggle,
        },
        methods: {
          extendRouteQuery({
            query,
            searchUrl,
          }: {
            query: Record<string, any>;
            searchUrl: string;
          }) {
            const state = store.state;
            query = { ...query };
            // パラメタ名を逆変換
            for (const [k, v] of Object.entries(state.paramNames)) {
              if (k !== v) {
                if (query[v]) {
                  delete query[v];
                }
                if (query[k]) {
                  query[v] = query[k];
                  delete query[k];
                }
              }
            }
            const orgQuery =
              searchUrl && state.dataMap[searchUrl]
                ? state.dataMap[searchUrl].orgQuery
                : state.orgQuery;
            return { ...orgQuery, ...query };
          },
        },
      });
    });
  }
}

// 処理済みのものをelmsから削除
cls2elms = omitBy(cls2elms, function (v, k) {
  return G.mfx.loaded[k];
});

if (Object.keys(cls2elms).length > 0) {
  G.mfx.lib = {
    ...G.mfx.lib,
    ...{
      // 外部から使えるように入れておく
      FormSerialize,
      qs,
    },
  };
  // 初期化ルーチン呼び出し
  main(cls2elms);
}
