
import Vue from 'vue';

export function isSameHostname(url: string): boolean {
  const parsed = new URL(url);
  return location.hostname === parsed.hostname;
}

export default Vue.extend({
  props: {
    customPropFunc: {
      type: Function,
    },
    customMethodFunc: {
      type: Function,
    },
  },
  computed: {
    self() {
      return this;
    },
    customProp(...args: []) {
      let ret;
      if (typeof this.customPropFunc === 'function') {
        ret = this.customPropFunc.apply(this, args);
      }
      return ret || {};
    },
  },
  methods: {
    customMethod(...args: []) {
      const func = (this as any)['customMethodFunc'];
      if (typeof func === 'function') {
        return func.apply(this, args);
      }
    },
  },
});
