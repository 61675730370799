







































































































































































































































































































import Vue from 'vue';
import { mapState } from 'vuex';
import {
  StoreState,
  SearchResult,
  OrganicDoc,
  OrgQuery,
} from '../store/store.entities';
import Base, { isSameHostname } from './Base.vue';
import MfSearchResultsHeader from './SearchResultsHeader.vue';
import MfSearchResultsPager, { PagerItem } from './SearchResultsPager.vue';
import MfRelatedKeywords from './RelatedKeywords.vue';
import DocIcon from '@/components/icons/DocIcon.vue';
import NoImage from '@/components/icons/NoImage.vue';
import PdfIcon from '@/components/icons/PdfIcon.vue';
import PptIcon from '@/components/icons/PptIcon.vue';
import XlsIcon from '@/components/icons/XlsIcon.vue';
import router from '@/router';

export default Vue.extend({
  name: 'SearchResults',
  components: {
    MfRelatedKeywords,
    MfSearchResultsHeader,
    MfSearchResultsPager,
    DocIcon,
    NoImage,
    PdfIcon,
    XlsIcon,
    PptIcon,
  },
  extends: Base,
  props: {
    ajaxUrl: {
      type: String,
      default: '',
    },
    pagerItemCount: {
      type: Number,
      validator: function (v) {
        return v > 0;
      },
      default: 5,
    },
    targetSelf: {
      type: Boolean,
      default: false,
    },
    targetAuto: {
      type: Boolean,
      default: false,
    },
    outboundDocClass: {
      type: String,
      default: 'mf_finder_doc_outbound',
    },
    disablePagelog: {
      type: Boolean,
      default: false,
    },
    pagerPagenumBlank: {
      type: Boolean,
      default: false,
    },
    disableResultframe: {
      type: Boolean,
      default: false,
    },
    showResultframeOnHover: {
      type: Boolean,
      default: false,
    },
    disableMultiViewer: {
      type: Boolean,
      default: false,
    },
    resultCallback: {
      type: String,
    },
    onResultFunc: {
      // 20170725
      type: Function,
    },
    hideHeader: {
      // 20170709
      type: Boolean,
      default: false,
    },
    hidePager: {
      // 201707
      type: Boolean,
      default: false,
    },
    useRelatedKeywords: {
      // 20170705
      type: Boolean,
      default: false,
    },
    titleFragsFunc: {
      // 20170718
      type: Function,
    },
    snippetFragsFunc: {
      // 20170718
      type: Function,
    },
    uriFragsFunc: {
      // 20170718
      type: Function,
    },
    disableClickHandler: {
      // 20180118
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      scrollSelector: 'html,body' as string,
      captureImageErrorUrls: {} as Record<string, boolean>,
    };
  },
  computed: {
    stateData(): StoreState {
      const state = this.$store.state;
      return (this.ajaxUrl ? state.dataMap[this.ajaxUrl] : state) || {};
    },
    searchResult(): SearchResult {
      return this.stateData.searchResult;
    },
    curImgsize(): number {
      return Number(this.stateData.imgsize);
    },
    ...mapState({
      searchUrl: 'searchUrl',
      availableResultframe: 'availableResultframe',
      mfHelper: 'mfHelper',
      // @return [[item,...], [item,...]]
    }),
    rootClass(): Record<string, boolean> {
      const map = {
        mf_available_resultframe:
          !this.disableResultframe && this.availableResultframe,
        mf_finder_organic_zerohit: this.hits === 0,
        mf_helper: this.mfHelper === 'SearchResults',
        [`mf_imgsize_${this.curImgsize}`]: true,
      };
      return map;
    },
    organic(): SearchResult['organic'] {
      return this.searchResult?.organic;
    },
    params(): OrgQuery {
      return this.searchResult?.params;
    },
    pagenum(): number {
      return parseInt(this.params.page as string);
    },
    hits(): number {
      try {
        return this.organic?.hits;
      } catch (e) {
        console.error(e);
      }
      return NaN;
    },
    captureImageUrls(): string[][] {
      const ret = [] as string[][];
      (this.organic.docs as any[]).forEach((doc, i) => {
        const imgs: string[] = [];
        // imgsに画像が配列で格納され、最初のインデックスが利用されるが、mf_zuba_image_url_s、mf_cc_key_url_sは現行、利用していない
        const zubaImg = doc.attrs && doc.attrs.mf_zuba_image_url_s;
        if (zubaImg && zubaImg.match(/https?:/)) {
          imgs.push(zubaImg);
        }
        const uriForCap =
          (doc.attr && doc.attr.mf_cc_key_url_s) || doc.thumbnail_url;
        imgs.push(uriForCap);
        ret[i] = imgs;
      });
      return ret;
    },
    linkTargets(): string[] {
      if (this.targetAuto) {
        return this.organic.docs.map((doc: any) =>
          isSameHostname(doc.uri) ? '_self' : '_blank',
        );
      } else {
        return Array(this.organic.docs.length).fill(
          this.targetSelf ? '_self' : '_blank',
        );
      }
    },
    pagerItems(): PagerItem[][] {
      const lastPage = Math.ceil(this.hits / Number(this.params.pagemax));
      let start, end;

      if (lastPage < this.pagerItemCount) {
        // 全体が足りないので、先頭から末尾まで
        start = 1;
        end = lastPage;
      } else if (this.pagenum < this.pagerItemCount / 2 + 1) {
        // 前が足りないので、先頭から
        start = 1;
        end = this.pagerItemCount;
      } else if (lastPage - this.pagenum < this.pagerItemCount / 2) {
        // 後ろが足りないので、末尾までmaxsize
        start = lastPage - this.pagerItemCount + 1;
        end = lastPage;
      } else {
        // 前後にmaxsize
        start = Math.floor(this.pagenum - this.pagerItemCount / 2 + 1);
        end = start + this.pagerItemCount - 1;
      }

      const ret = [[], []] as PagerItem[][];

      for (let page = start; page < this.pagenum; page++) {
        ret[0].push({ page, params: this.extendRouteQuery({ page }) });
      }
      for (let page = this.pagenum + 1; page <= end; page++) {
        ret[1].push({ page, params: this.extendRouteQuery({ page }) });
      }
      return ret;
    },
    pagerBeforeItems(): PagerItem[] {
      return this.pagerItems[0];
    },
    pagerAfterItems(): PagerItem[] {
      return this.pagerItems[1];
    },
    pagerFirstParams(): any {
      const page = 1;
      return this.extendRouteQuery({ page });
    },
    pagerPrevParams(): any {
      const page = this.pagenum - 1;
      if (!(page >= 1)) return null;

      return this.extendRouteQuery({ page });
    },
    pagerNextParams(): any {
      let page = this.pagenum;
      if (!(page * parseInt(this.params.pagemax) < this.organic.hits))
        return null;

      page++;
      return this.extendRouteQuery({ page });
    },
    parentTarget(): DOMTokenList {
      return this.$parent.$el.classList;
    },
    isPageBeyondLastPage(): boolean {
      if (!this.hits) return false;
      const lastPage = Math.ceil(this.hits / Number(this.params.pagemax));
      return lastPage < this.pagenum;
    },
  },
  methods: {
    onCaptureImageError(imageUrl: string) {
      this.$set(this.captureImageErrorUrls, imageUrl, true);
    },
    extendRouteQuery(params: Record<string, any>): any {
      return (this.$root as any).extendRouteQuery({
        query: params,
        searchUrl: this.ajaxUrl,
      });
    },
    /** For zoom mega preview, temporarily disabled feature */
    showResultframe(doc: any, event: any, needCheck: boolean) {
      try {
        // リザルトフレーム無効
        if (this.disableResultframe) return;

        // PDF等の場合
        if (doc.opts && doc.opts.isPDF) {
          // console.log(doc.opts)
          // マルチビューア無効
          if (this.disableMultiViewer) return;
          // console.log(doc.opts.canview)
          // unitのmuv無効
          if (!doc.opts.canview) return;
          // console.log('ok')
        }

        needCheck = needCheck && !this.showResultframeOnHover;
        this.$store.dispatch('showResultframe', { doc, event, needCheck });
      } catch (e) {
        console.error(e);
      }
    },
    getFragClass(frag: any) {
      const ret: string[] = [];
      if (frag.id > 0) {
        ret.push('mf_finder_mark');
      }
      if (frag.type) {
        ret.push('mf_finder_frag_' + frag.type);
      }
      return ret;
    },
    titleFrags(doc: OrganicDoc) {
      let ret;
      if (typeof this.titleFragsFunc === 'function') {
        ret = this.titleFragsFunc.apply(this, arguments);
      }
      if (typeof ret === 'string') {
        return [{ id: 0, text: ret }];
      }
      //For PDF
      if (!doc.title && doc.doctype === 'pdf') {
        // Delete hash and query from the title
        let includeHashIndex = doc.uri.indexOf('#');
        let includeQueryIndex = doc.uri.indexOf('?');

        let pdfTitle = doc.uri;

        // For deleting hash
        if (includeHashIndex !== -1) {
          pdfTitle = doc.uri.substring(0, includeHashIndex);
        }

        // For deleting query
        if (includeQueryIndex !== -1) {
          pdfTitle = doc.uri.substring(0, includeQueryIndex);
        }

        // Extract the title.
        // From http(s)://~~~~/~~~.pdf
        // From http(s)://~~~~/~~~.cgi etc...
        let lastSlashIndex = pdfTitle.lastIndexOf('/');
        let afterLastSlash = decodeURIComponent(
          pdfTitle.substring(lastSlashIndex + 1),
        );

        // When the last part of the URL is a PDF file
        if (afterLastSlash.includes('.pdf') && afterLastSlash) {
          let pdfTitleWithoutExt = afterLastSlash.split('.pdf')[0];
          return [{ id: 0, text: pdfTitleWithoutExt }];
        }

        // When the last part of the URL is not a PDF file. But it has a dot.
        if (
          !afterLastSlash.includes('.pdf') &&
          afterLastSlash.includes('.') &&
          afterLastSlash
        ) {
          return [{ id: 0, text: afterLastSlash }];
        }

        return [{ id: 0, text: '' }];
      }
      if (!ret) {
        if (doc.highlight.title.length === 0) {
          ret = [{ id: 0, text: doc.title || 'No title' }];
        } else {
          ret = doc.highlight.title;
        }
      }
      return ret;
    },
    // TODO: check if is necessary to process like in titleFrags()
    snippetFrags(doc: OrganicDoc) {
      let ret;
      if (typeof this.snippetFragsFunc === 'function') {
        ret = this.snippetFragsFunc.apply(this, arguments);
      }
      if (typeof ret === 'string') {
        return [{ id: 0, text: ret }];
      }
      if (!ret) {
        // priority
        // 1. doc.highlight.documentText;
        // 2. doc.highlight.description;
        // 3. doc.startText;
        if (doc.highlight.documentText.length) {
          ret = doc.highlight.documentText;
        } else if (doc.highlight.description.length) {
          ret = doc.highlight.description;
        } else {
          ret = [
            {
              id: 0,
              text: ((doc.description || '') + (doc.startText || '')).slice(
                0,
                102,
              ),
            },
          ];
        }
      }
      return ret;
    },
    uriFrags(doc: OrganicDoc) {
      let ret;
      if (typeof this.uriFragsFunc === 'function') {
        ret = this.uriFragsFunc.apply(this, arguments);
      }
      if (typeof ret === 'string') {
        return [{ id: 0, text: ret }];
      }
      if (!ret) {
        if (doc.highlight.uri.length === 0) {
          ret = [{ id: 0, text: doc.uri }];
        } else {
          ret = doc.highlight.uri;
        }
      }
      return ret;
    },
    classForDoc(doc: OrganicDoc): string[] {
      const ret: string[] = [];
      if (!isSameHostname(doc.uri)) {
        ret.push(this.outboundDocClass);
      }
      return ret;
    },
  },
  watch: {
    searchResult() {
      this.captureImageErrorUrls = {};
      // pageOverLastPageがtrueの場合、ページ数を1に戻す
      if (this.isPageBeyondLastPage) {
        const page = 1;
        const params = this.extendRouteQuery({ page });
        router.push({ path: '', query: params });
      }
      if (
        this.resultCallback &&
        typeof (window as any)[this.resultCallback] === 'function'
      ) {
        this.$nextTick(() => {
          (window as any)[this.resultCallback].apply(this);
        });
      } else if (typeof this.onResultFunc === 'function') {
        this.$nextTick(() => {
          this.onResultFunc.apply(this);
        });
      }
      if (this.scrollSelector) {
        const elms = document.querySelectorAll(this.scrollSelector);
        for (let i = 0; i < elms.length; i++) {
          elms[i].scrollTop = 0;
        }
      }
      // when search result is updated, update the zero hit class
      if (this.hits === 0) {
        this.parentTarget.add('mf_finder_organic_zerohit');
      } else {
        this.parentTarget.remove('mf_finder_organic_zerohit');
      }
    },
  },
  mounted() {
    if (this.ajaxUrl) {
      this.$store.dispatch('updateSearchUrl', this.ajaxUrl);
    }
    if (this.disablePagelog) {
      this.$store.commit('disablePagelog');
    }
    this.$store.commit('searchResultsMounted');
    if (this.useRelatedKeywords) {
      this.$store.dispatch('enableRelatedKeywords', {
        searchUrl: this.ajaxUrl,
      });
    }
  },
});
