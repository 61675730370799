













import Base from './Base.vue';
import Vue from 'vue';
import { SearchResult, StoreState } from '../store/store.entities';

export default Vue.extend({
  name: 'mf-drilldown-reset',
  extends: Base,
  props: {
    ajaxUrl: {
      type: String,
      default: '',
    },
    resetText: {
      type: String,
      default: 'reset',
    },
    bodyClassSelected: {
      type: String,
      default: 'mf_finder_drilldown_selected',
    },
  },
  data: function () {
    return {};
  },
  computed: {
    stateData(): StoreState {
      const state = this.$store.state;
      return (this.ajaxUrl ? state.dataMap[this.ajaxUrl] : state) || {};
    },
    searchResult(): SearchResult {
      return this.stateData.searchResult;
    },
    noselects(): boolean[] {
      return this.searchResult?.dd?.noselect;
    },
  },
  methods: {
    extendRouteQuery(params: Record<string, any>) {
      const resetParam = {
        page: 1,
      };
      return (this.$root as any).extendRouteQuery({
        query: { ...resetParam, ...params },
        searchUrl: this.ajaxUrl,
      });
    },
  },
  watch: {
    noselects() {
      if (this.bodyClassSelected) {
        document.body.classList.toggle(
          this.bodyClassSelected,
          this.noselects && !this.noselects[0],
        );
      }
    },
  },
  mounted() {
    if (this.ajaxUrl) {
      this.$store.dispatch('updateSearchUrl', this.ajaxUrl);
    }
  },
});
