import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { G } from '../common/utils';

Vue.use(VueRouter);

// 検索結果ページのみハッシュモードを有効にする
// TODO: 他でも、mf-search-results の存在チェックを行ってるので共通化する
const searchResultsCustomEle = document.querySelector('mf-search-results');

const router = new VueRouter({
  mode: searchResultsCustomEle !== null ? G.mfx.router_mode : 'history',
  routes: [{ path: '' }] as Array<RouteConfig>,
});

export default router;
