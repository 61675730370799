




















































import { mapState } from 'vuex';
//@ts-ignore
import Base, { isSameHostname } from './Base.vue';
import Vue from 'vue';
import { OrgQuery, SearchResult, StoreState } from '@/store/store.entities';
import {
  FeaturedContents,
  FeaturedContentsDoc,
} from '@/openapi-clients/finder_service';
import NoImage from '@/components/icons/NoImage.vue';

export default Vue.extend({
  name: 'FeaturedContents',
  components: { NoImage },
  extends: Base,
  props: {
    ajaxUrl: {
      type: String,
      default: '',
    },
    targetSelf: {
      type: Boolean,
      default: false,
    },
    targetAuto: {
      type: Boolean,
      default: false,
    },
    outboundDocClass: {
      type: String,
      default: 'mf_finder_doc_outbound',
    },
    showInAllPages: {
      type: Boolean,
      default: false,
    },
    disableResultframe: {
      type: Boolean,
      default: false,
    },
    disableMultiViewer: {
      type: Boolean,
      default: false,
    },
    resultCallback: {
      type: String,
    },
    disableClickHandler: {
      // 20201205
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      erroredImages: {} as Record<string, boolean>,
    };
  },
  computed: {
    stateData(): StoreState {
      const state = this.$store.state;
      return (this.ajaxUrl ? state.dataMap[this.ajaxUrl] : state) || {};
    },
    searchResult(): SearchResult {
      return this.stateData.searchResult;
    },
    curImgsize(): number {
      return Number(this.stateData.imgsize);
    },
    ...mapState({
      availableResultframe: 'availableResultframe',
      mfHelper: 'mfHelper',
    }),
    rootClass(): Record<string, boolean> {
      const map = {
        mf_available_resultframe:
          !this.disableResultframe && this.availableResultframe,
        mf_helper: this.mfHelper === 'FeaturedContents',
        [`mf_imgsize_${this.curImgsize}`]: true,
      };
      return map;
    },
    featured_contents(): FeaturedContents {
      return this.searchResult?.featured_contents;
    },
    params(): OrgQuery {
      return this.searchResult?.params;
    },
    pageNum(): number {
      return parseInt(this.params?.page);
    },
    linkTargets(): string[] {
      if (this.targetAuto) {
        return this.featured_contents.docs.map((doc: any) =>
          isSameHostname(doc.url) ? '_self' : '_blank',
        );
      } else {
        return Array(this.featured_contents.docs.length).fill(
          this.targetSelf ? '_self' : '_blank',
        );
      }
    },
  },
  methods: {
    showResultframe(doc: any, event: Event) {
      // console.log('showResultframe')
      try {
        // リザルトフレーム無効
        if (this.disableResultframe) return;

        // PDF等の場合
        if (doc.opts && doc.opts.isPDF) {
          // console.log(doc.opts)
          // マルチビューア無効
          if (this.disableMultiViewer) return;
          // console.log(doc.opts.canview)
          // unitのmuv無効
          if (!doc.opts.canview) return;
          // console.log('ok')
        }

        this.$store.dispatch('showResultframe', { doc, event });
      } catch (e) {
        console.error(e);
      }
    },
    classForDoc(doc: FeaturedContentsDoc): string[] {
      const ret: string[] = [];
      if (!isSameHostname(doc.url)) {
        ret.push(this.outboundDocClass);
      }
      return ret;
    },
    getThumbnailUrl(doc: FeaturedContentsDoc): string {
      let returnValue = '';
      returnValue = doc.thumbnail_url || returnValue;
      return returnValue;
    },
    onThumbnailError(doc: FeaturedContentsDoc) {
      this.$set(this.erroredImages, doc.url, true);
    },
  },
  watch: {
    searchResult() {
      if (
        this.resultCallback &&
        typeof (window as any)[this.resultCallback] === 'function'
      ) {
        this.$nextTick(() => {
          (window as any)[this.resultCallback]();
        });
      }
    },
  },
  mounted() {
    if (this.ajaxUrl) {
      this.$store.dispatch('updateSearchUrl', this.ajaxUrl);
    }
  },
});
