export type FinderApiPathParts = {
  baseUrl?: string;
  serviceUniqueName?: string;
  ids?: {
    contractId: number;
    servicegroupId: number;
    serviceId: number;
  };
};

/**
 * In case of
 * `https://finder.blue.api.mf3stg.marsflag.com/api/v1/finder_service/documents/f1fddd3e/search`
 * Group 1: `https://finder.blue.api.mf3stg.marsflag.com/api/v1/finder_service`
 * Group 2: f1fddd3e
 *
 * In case of
 * `https://finder.blue.api.mf3stg.marsflag.com/api/v1/finder_service/documents/1/1/1/search`
 * Group 1: `https://finder.blue.api.mf3stg.marsflag.com/api/v1/finder_service`
 * Group 2: 1/1/1
 */
const apiPartsRegex = /(.*)\/documents\/(.+)\/search/;

export const extractFinderApiPathParts = (
  searchUrl: string,
): FinderApiPathParts | null => {
  const parts = apiPartsRegex.exec(searchUrl);
  if (!parts) {
    console.error('irregular searchUrl');
    return null;
  }
  const [, baseUrl, paramsPart] = parts;
  const params = paramsPart.split('/');
  const returnValue: FinderApiPathParts = {
    baseUrl,
  };
  if (params.length === 1) {
    returnValue.serviceUniqueName = params[0];
  } else if (params.length === 3) {
    returnValue.ids = {
      contractId: Number(params[0]),
      servicegroupId: Number(params[1]),
      serviceId: Number(params[2]),
    };
  } else {
    console.error(
      'searchUrl path parameters number is wrong. Only one (service_unique_name) or three parameters (ids) are accepted',
    );
    return null;
  }

  return returnValue;
};

/**
 * 解る検索を発動するか否かを window.matchMedia(～) でチェックする。
 * GALFSRAM.mfx.resultframeMatchMedia が設定されていればそれを使う。
 * デフォルトは (min-width:640px)
 */
export function checkResultframeMatchMedia(): boolean {
  try {
    return window.matchMedia(G.mfx.resultframeMatchMedia || '(min-width:640px)')
      .matches;
  } catch (e) {
    console.error(e);
  }
  return false;
}

// 入れ物を作る
const galfsram = ((window as Record<string, any>).GALFSRAM =
  (window as Record<string, any>).GALFSRAM || {});
galfsram.mfx = {
  loaded: {},
  ajaxUrlRegExp: null,
  ...galfsram.mfx,
};
export const G = galfsram;
